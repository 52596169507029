import { PageProps } from "gatsby";
import React from "react";
import BasicScreen from "../components/BasicScreen";

const AboutPage = (props: PageProps) => {
  return (
    <BasicScreen {...props}>
      <div className="hidden md:block absolute top-22 left-1/4 w-1/4 h-28 bg-blue -z-10" />
      <div className="flex w-full p-4 flex-col md:mt-22 ">
        <div className="bg-blue p-4  w-full flex flex-col ">
          <span className="mb-2 text-2xl">O nama</span>
          <span className="mb-6">
            Proident sint proident nostrud quis. Consequat qui incididunt dolore aute ipsum. Fugiat veniam est amet nisi
            esse sint. Proident sint proident nostrud quis. Consequat qui incididunt dolore aute ipsum. Fugiat veniam
            est amet nisi esse sint.
          </span>
          <span className="mb-6">
            Proident sint proident nostrud quis. Consequat qui incididunt dolore aute ipsum. Fugiat veniam est amet nisi
            esse sint. Proident sint proident nostrud quis. Consequat qui incididunt dolore aute ipsum. Fugiat veniam
            est amet nisi esse sint.
          </span>
          <span>
            Proident sint proident nostrud quis. Consequat qui incididunt dolore aute ipsum. Fugiat veniam est amet nisi
            esse sint. Proident sint proident nostrud quis. Consequat qui incididunt dolore aute ipsum. Fugiat veniam
            est amet nisi esse sint.
          </span>
        </div>
      </div>
    </BasicScreen>
  );
};

export default AboutPage;
